<template lang="pug">
BaseForm(v-if='!formSubmitted' id='saveCart' data-cypress='form' @submit='submitForm')
  BaseFormInputFullName(v-model='fullName' name='name' placeholder='Enter Full Name' validation='required')
  BaseFormInputEmail(v-model='email' label='Your Email' name='email' placeholder='Enter Email' validation='required')
  p(class='mb-10') Add your Name and Email Address and we will send you an email with a link to this cart so you can use it on another browser or device

  div(class='flex items-center')
    div(v-if='$sitewideConfig.config.privacyUrl?.cached_url' class='flex-1 pr-3 text-center')
      nuxt-link(:to='$sitewideConfig.config.privacyUrl.cached_url' class='uppercase text-sm') Privacy Policy

    div(class='flex-1 pl-3')
      BaseButton(
        form='saveCart'
        type='submit'
        data-cypress='submit'
        :is-waiting='isWaiting'
        :is-disabled='!isFilled'
        icon='SAVE'
        class='w-full'
      ) Send

div(v-else class='text-center')
  CheckmarkIcon(class='w-20 h-20 fill-success mx-auto mb-4')
  p(class='font-bold text-lg uppercase mb-2') Your cart has been sent
  p An email has been sent to the address provided with instructions to access it.
</template>

<script setup lang="ts">
import CheckmarkIcon from '@/assets/checkmarkThin.svg?component'

const { $sitewideConfig } = useNuxtApp()
const cart = useCart()

const fullName = ref('')
const email = ref('')
const formSubmitted = ref(false)
const isWaiting = ref(false)

const isFilled = computed(() => {
  return !!(fullName.value && email.value)
})

async function submitForm() {
  if (isWaiting.value) return
  isWaiting.value = true

  await cart.share(fullName.value, email.value)

  formSubmitted.value = true
  isWaiting.value = false
}
</script>
